<template>
  <div class="card appView products mailinline">
    <div class="card-body" v-if="loading">
      <Loading :loading="loading" v-if="loading" :type="'inner'" style="position: relative;" :height="'250px'"/>
    </div>
    <template v-else>
    <div class="card-body " v-if="mails && mails.combined && mails.combined.length > 0" >
      <div class="mail-item " v-for="mail in mails.combined" v-bind:key="mail.id">
        <template v-if="mail._type === 'mail'">
          <div class="row mb-3 pb-3 borderRow">
            <!--{{ mail }}-->
            <div class="col-9 justify-content-left align-self-center">
              <div class="lineitem l-state">
                <template v-if="mail.todo_state === 'finished'"><span tooltip="erledigt" position="right"><i
                  class="fa-duotone fa-check-circle"></i></span></template>
                <template v-if="mail.todo_state === 'stopped'"><span tooltip="gestoppt" position="right"><i
                  class="fa-duotone fa-circle-xmark"></i></span></template>
                <template v-if="mail.todo_state === 'executable'"><span tooltip="In der Warteschlange"
                                                                         position="right"><i
                  class="fa-duotone fa-snake"></i></span></template>
                <template v-if="mail.todo_state === 'queued'"><span tooltip="In Vorbereitung" position="right"><i
                  class="fa-duotone fa-snake"></i></span></template>
                <template v-if="mail.todo_state === 'failed'"><span tooltip="Fehlgeschlagen" position="right"><i
                  class="fa-duotone fa-file-excel"></i></span></template>
              </div>
              <div class="lineitem l-titlesmall">
                <p v-if="mail.todo_type === 'employee'" class="text-xs text-secondary mb-0">Mitarbeiter</p>
                <p class="text-xs text-secondary mb-0" v-else>{{ mail.customerFormatted }} </p>
              </div>
              <div class="lineitem l-type l-icon">
                <template v-if="mail.todo_type === 'document'">
                  <span tooltip="Belegversand" position="right"><i class="fa-duotone fa-file-contract"></i></span>
                </template>
                <template v-if="mail.todo_type === 'employee'">
                  <span tooltip="Nachricht an den Mitarbeiter" position="right"><i class="fa-duotone fa-id-card-clip"></i></span>
                </template>
                <template v-if="mail.todo_type === 'customer'">
                  <span tooltip="Nachricht an den Kunden" position="right"><i class="fa-duotone fa-users"></i></span>
                </template>
                <template v-if="mail.todo_type === 'task'">
                <span tooltip="Nachricht über Ticketsystem" position="right"><i
                  class="fa-duotone fa-clipboard-list-check"></i></span>
                </template>
              </div>
              <div class="lineitem l-task l-icon">
                <template v-if="mail.todo_task === 'sendMail'">
                  <template v-if="mail.payload && mail.payload.receivers && mail.payload.receivers.direct">
                  <span :tooltip="'E-Mail an '+mail.payload.receivers.direct" position="right"><i
                    class="fa-duotone fa-envelope"></i></span>
                  </template>
                  <template v-else>
                    <span tooltip="E-Mailversand" position="right"><i class="fa-duotone fa-envelope"></i></span>
                  </template>
                </template>
                <template v-if="mail.todo_task === 'delete'"><span tooltip="Löschung" position="right"><i
                  class="fa-duotone fa-trash"></i></span></template>
              </div>
              <div class="lineitem l-job l-datetime">
              <span class="text-xs text-secondary" tooltip="geplanter Ausführungszeitpunkt" position="right"><i
                class="fa-duotone fa-timer"></i> {{
                  $filters.formatDate(mail.todo_time)
                }}<br>{{ $filters.formatTime(mail.todo_time) }}</span>
              </div>
              <div class="lineitem l-finished l-datetime">
                    <span class="text-xs text-secondary" tooltip="ausgeführt" position="right">
                      <template v-if="mail.finish_time && mail.finish_time !== '0000-00-00 00:00:00'"><i
                        class="fa-duotone fa-flag-checkered"></i> {{
                          $filters.formatDate(mail.finish_time)
                        }}<br>{{ $filters.formatTime(mail.finish_time) }}</template>
                      <template v-else><i class="fa-duotone fa-flag-checkered"></i> geplant</template>
                    </span>
              </div>
              <div class="lineitem l-date l-datetime">
              <span class="text-xs text-secondary mb-0" tooltip="Erstellt" position="right"><i
                class="fa-duotone fa-calendar-plus"></i> {{
                  $filters.formatDate(mail.created_time)
                }}<br>{{ $filters.formatTime(mail.created_time) }}</span>
              </div>

              <div class="lineitem l-mails">
              <span tooltip="Betreff" position="right"
                    v-if="mail.payload && mail.payload.subject">{{ mail.payload.subject }}</span>
                <span tooltip="Nachricht" position="right" v-else>{{ mail.message }}</span>
              </div>
            </div>
            <div class="col-3 text-end  justify-content-center align-self-center">
              <!-- if attachments lenght print icon -->
              <span v-if="mail.payload.attachments.length > 0" style="margin-right: 6px;">
              <i class="fa-duotone fa-paperclip"></i> {{ mail.payload.attachments.length }}
            </span>
              <button type="button" class="btn btn-outline-primary btn-sm" @click="setMail(mail);"
                      :tooltip="this.$t('globals.view')" :position="'left'"><i
                class="fa-duotone fa-eye"></i>
              </button>
            </div>
          </div>
        </template>
        <template v-else-if="mail._type === 'file'">
          <div class="row mb-3 pb-3 borderRow">
            <div class="col-9  justify-content-center align-self-center">
              <p class="mb-0 text-sm font-weight-bold">
                {{ $filters.formatDateTime(mail.uploaded_on) }} <span tooltip="Datei"><i class="fa-duotone fa-file"></i></span></p>
              <p class="mb-0 text-xs">{{ mail.title }}</p>
            </div>
            <div class="col-3">
              <button class="btn btn-outline-danger btn-sm float-end ms-2" @click="deleteFile(mail.customer_id, mail.id)" :tooltip="$t('globals.delete')"><i
                class="fa-duotone fa-trash"></i></button>
              <div class="btn btn-outline-primary btn-sm float-end" @click="downloadFile(mail.customer_id, mail.id)"
                   :tooltip="this.$t('globals.download')" :position="'left'"><i class="fa-duotone fa-download"></i>
              </div>

            </div>
          </div>
        </template>
        <template v-else-if="mail.data && mail.data._type === 'archive'">

          <div class="row mb-3 pb-3 borderRow">
            <div class="col-9">
              <p class="mb-0 text-sm font-weight-bold"><img style="max-width: 40px"
                                                            v-if="getEmployees && getEmployees.length > 0 && mail.data.employee_id"
                                                            :src="$filters.employeeIcon(getEmployees, mail.data.employee_id, 'id')"
                                                            class="sketchAvatar">
                {{ $filters.formatDateTime(mail.data.crm_created_date) }} <span tooltip="Archivierung"><i class="fa-duotone fa-archive"></i></span>
              </p>
              <p class="mb-0 text-xs">{{ mail.data.title }}</p>
            </div>
            <div class="col-3">
              <button type="button" class="btn btn-outline-primary btn-sm float-end" @click="setMail(mail.data);"
                      :tooltip="this.$t('globals.view')" :position="'left'"><i
                class="fa-duotone fa-eye"></i>
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="card-body" v-else><p class="noItemsFound">{{ $t("activity.noEntriesFound") }}</p></div></template>
  </div>
  <Modal v-if="activeMail">
    <div class="modal  show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel" v-if="activeMail.payload && activeMail.payload.subject">{{ activeMail.payload.subject }}</h5>
            <h5 v-else>
              <template v-if="activeMail.title">{{activeMail.title}}</template>
            </h5>
            <button type="button" class="btn-close" @click="resetMail" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div v-if="activeMail.payload && activeMail.payload.body" v-html="activeMail.payload.body"></div>
            <div v-else v-html="activeMail.description"></div>
            <div class="attachments" v-if="activeMail.payload && activeMail.payload.attachments && activeMail.payload.attachments.length">
              <h5><i class="fa-duotone fa-paperclip"></i> {{ $t("globals.attachments") }}</h5>
              <ul>
                <li v-for="(att, inn) in activeMail.payload.attachments">
                  <button class="btn btn-outline-primary btn-sm mb-2 me-2" @click="openAttachmentFromMail(activeMail._taskId, inn)"><i
                    class="fa-duotone fa-paperclip"></i> {{ $t("globals.data") }} {{ inn + 1 }}
                  </button>

                </li>
              </ul>
            </div>
            <template v-else>
              <template v-if="activeMail.attachments && activeMail.attachments.length">
                <h5><i class="fa-duotone fa-paperclip"></i> {{ $t("globals.attachments") }}</h5>
                <ul>
                  <li v-for="(att, inn) in activeMail.attachments">
                    <button class="btn btn-outline-primary btn-sm  mb-2 me-2" @click="openAttachmentFromMail(activeMail._taskId, inn)"><i
                      class="fa-duotone fa-paperclip"></i> {{ $t("globals.data") }} {{ inn + 1 }}
                    </button>
                  </li>
                </ul>
              </template>
            </template>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-secondary" @click="resetMail"><i
              class="fa-duotone fa-xmark-circle"></i>{{ $t("globals.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";

export default {
  name: "CustomerMails",
  props: {
    customerId: {}
  },
  data() {
    return {
      mails: [],
      file: '',
      activeMail: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["getPlugins", "getEmployees", "customerActive", "modalsList"]),
  },
  components: {
    Loading
  },
  methods: {
    openAttachmentFromMail(mailId, key) {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails/" + mailId + "/attachment/" + key, {headers}).then((r) => {
        const file = r.data.result.data;
        const linkSource = 'data:' + file.mimeType + ';base64,' + file.file;
        // download fileName
        // trigger download
        var a = window.document.createElement('a');
        a.setAttribute('href', linkSource);
        a.setAttribute('download', file.filename);
        window.document.body.appendChild(a);
        a.click();
      });
    },
    getMails() {
      this.loading = true;
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + this.customerActive.id + "/mails", {headers}).then((r) => {
        this.mails = r.data.result.data;
        this.loading = false;
      });
    },
    setMail(mail) {
      this.activeMail = mail;
    },
    resetMail() {
      this.activeMail = null;
    },
    downloadFile(customerId, attachmentId) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "customer/" + customerId + "/readFile/" + attachmentId, {headers: headers})
        .then((response) => {
          let file = response.data.result.data;
          this.file = file;
          const linkSource = 'data:' + file.contentType + ';base64,' + file.content;
          // download fileName
          // trigger download
          var a = window.document.createElement('a');
          a.setAttribute('href', linkSource);
          a.setAttribute('download', file.title);
          window.document.body.appendChild(a);
          a.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteFile(customerId, mailId){
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // confirm delete first
      this.$confirm('Soll diese Datei gelöscht werden?', 'Datei löschen', "question", {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein',
        confirmButtonColor: '#0088CC',
        type: 'warning'
      }).then(() => {
      axios.delete(this.$store.state.apiUrl + "customer/" + customerId + "/file/" + mailId, {headers: headers})
        .then((response) => {
          this.getMails();
        })
        .catch((error) => {
          console.log(error);
        });}).catch((err) => {console.log(err)});
    },
    handleFileReload() {
      this.getMails();
    }
  },
  created() {
    this.getMails();
    window.addEventListener('triggerReloadFiles', this.handleFileReload);
  },
  beforeDestroy() {
    window.removeEventListener('triggerReloadFiles', this.handleFileReload);
  },
  watch: {
    customerId: function () {
      this.getMails();
    },
  }
};
</script>
<style lang="scss">
.modal {
  z-index: 10000 !important;
}

.modal-backdrop {
  z-index: 9999 !important;
}
.mailinline {
  .col-9.justify-content-left.align-self-center {
    display: flex;}

  .lineitem.l-titlesmall, .lineitem.l-type, .lineitem.l-state , .lineitem.l-task  {
    width: 35px;
  }
  .lineitem.l-mails {
    width: 250px;
  }
  .lineitem .fa-check-circle, .lineitem .fa-id-card-clip {
    color: #3b8130;
  }
  .lineitem.l-datetime {
    width: 120px;
  }

  .lineitem .fa-file-contract,
  .lineitem .fa-snake,
  .lineitem .fa-users {
    color: #08c;
  }
}
</style>
