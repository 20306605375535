
<template>
  <div class="helpbox" :class="color">
    <div class="helpbox__header" :class="(showHelpBox) ? 'open': 'closed'">

      <h4 class="helpbox__title"  @click="toggleHelpBox">
        <i class="fa-duotone" :class="'fa-'+icon"></i> {{title}}
        <button class="helpbox__close">
          <i class="fa-duotone" :class="(showHelpBox) ? 'fa-chevrons-up' : 'fa-chevrons-down'"></i>
        </button></h4>

    </div>
    <div class="helpbox__content" v-if="showHelpBox">
      <div class="content">
        <slot>
          {{ content }} <!-- Fallback content if no slot is provided -->
        </slot>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: 'HelpBox',
  props: {
    title: {
      type: String,
      default: 'Hilfe'
    },
    content: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'blue'
    },
    icon: {
      type: String,
      default: 'info-square'
    }
  },
  data() {
    return {
      showHelpBox: false
    }
  },
  methods: {
    toggleHelpBox() {
      this.showHelpBox = !this.showHelpBox
    }
  }
}
</script>
<style scoped lang="scss">
</style>
